import React from 'react'
import styled from 'styled-components'
import ReflowHubInverseLogo from '../../images/RH-logo-horizontal-inverse.svg'
import ReflowHubPrimaryLogo from '../../images/RH-logo-horizontal.svg'

const Small = styled.div`
font-family: Inter;
font-style: normal;
font-weight: 600;
font-size: 10px;
`

const Logo = styled.img`
width: 107px;
height: 14px;
object-fit: contain;
margin-left: 7px;
`

const PoweredBy = ({ variant = 'primary' }) => {
  return <div className="d-flex flex-row align-items-center">
      <Small>POWERED BY</Small>
      <Logo src={variant === 'inverse' ? ReflowHubInverseLogo : ReflowHubPrimaryLogo} />
    </div>
}

export default PoweredBy
