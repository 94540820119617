
import axios from 'axios'
import { getApiUrl } from './Environment'

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 30000,
  baseURL: getApiUrl()
}

export const client = axios.create(axiosConfig)
